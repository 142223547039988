import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useRouter} from 'next/router';
import {useStore} from '../components/StoreProvider';
import {LoadingState} from '../components/LoadingState/LoadingState';
export {getStaticProps} from '@amplify/abflag';

const IndexPage: React.FC = () => {
    const store = useStore();
    const router = useRouter();

    const {route} = store.uiStore.progressStore;

    useEffect(() => {
        if (router.pathname === '/') {
            const queryParams = new URLSearchParams(router.query as Record<string, string>);
            const queryParamStringify = queryParams.toString().length > 0 ? `?${queryParams}` : '';

            if (queryParamStringify.length > 0) {
                store.uiStore.progressStore.initializeRoute(queryParamStringify);
            }

            router.push(`${route}${queryParamStringify}`);
        }
    }, [router.pathname]);

    return <LoadingState height="100vh" />;
};

export default observer(IndexPage);
